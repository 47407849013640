<div class="relative mt-8 h-56 lg:mt-0">
	<!-- Project Languages -->
	<div class="w-40">
		<button
			aria-expanded="true"
			data-state="open"
			id="UfsW8ctN6U"
			aria-haspopup="menu"
			data-orientation="horizontal"
			role="menuitem"
			data-melt-menubar-trigger=""
			data-menu-trigger=""
			type="button"
			class="flex cursor-default select-none items-center rounded-sm px-3 py-1 text-sm font-medium outline-none focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground"
			tabindex="0"
			data-highlighted="">Language</button
		>
		<div
			role="menu"
			data-state="open"
			data-portal=""
			tabindex="0"
			data-melt-menubar-menu=""
			data-menu-content=""
			class="pointer-events-none mt-1 min-w-[12rem] rounded-md border bg-popover p-1 text-popover-foreground shadow-md"
			data-escapee=""
			data-side="bottom"
			data-align="start"
		>
			<p class="my-2 text-center text-sm text-muted-foreground">Project Languages</p>
			<div
				role="separator"
				aria-hidden="false"
				data-orientation="horizontal"
				data-melt-separator=""
				data-menu-separator=""
				class="-mx-1 my-1 h-px bg-muted"
			></div>
			<!--<Menubar-separator>-->
			<div
				tabindex="-1"
				data-orientation="vertical"
				data-melt-menubar-menu-checkbox-item=""
				data-menu-checkbox-item=""
				class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
				data-melt-menu-id="nG4ZImao3y"
			>
				Hindi
			</div>
			<!--<Menubar-checkbox-item>-->
			<div
				tabindex="-1"
				data-orientation="vertical"
				aria-checked="true"
				data-state="checked"
				data-melt-menubar-menu-checkbox-item=""
				data-menu-checkbox-item=""
				class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
				data-melt-menu-id="nG4ZImao3y"
			>
				<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"
					><div data-menu-checkbox-indicator="">
						<svg
							width="24"
							height="24"
							class="h-4 w-4"
							role="img"
							aria-label="check,"
							viewBox="0 0 15 15"
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
							><path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
								fill="currentColor"
							></path></svg
						>
					</div></span
				> Malayalam
			</div>
			<!--<Menubar-checkbox-item>-->
			<div
				tabindex="-1"
				data-orientation="vertical"
				data-melt-menubar-menu-checkbox-item=""
				data-menu-checkbox-item=""
				class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
				data-melt-menu-id="nG4ZImao3y"
			>
				Tamil
			</div>
			<!--<Menubar-checkbox-item>-->
		</div>
	</div>

	<!-- Transliterate -->
	<div
		class="absolute left-[46%] top-[-10%] col-span-2 col-start-5 w-44 lg:left-[45%] lg:top-[29%]"
	>
		<div
			role="application"
			data-cmdk-root=""
			class="pointer-events-none flex h-full w-40 flex-col overflow-hidden rounded-lg border bg-popover text-popover-foreground shadow-md"
		>
			<div class="flex items-center border-b px-3" data-cmdk-input-wrapper="">
				<svg
					width="24"
					height="24"
					class="mr-2 h-4 w-4 shrink-0 opacity-50"
					role="img"
					aria-label="magnifying glass,"
					viewBox="0 0 15 15"
					fill="currentColor"
					xmlns="http://www.w3.org/2000/svg"
					><path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
						fill="currentColor"
					></path></svg
				>
				<p class="relative flex h-10 items-center">
					<span
						class="pointer-events-none w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground"
					>
						Malayalam
						<span class="h-4 w-[1px] animate-ping border border-foreground"></span>
					</span>
				</p>
			</div>
			<div
				data-cmdk-list=""
				role="listbox"
				aria-label="Suggestions"
				class="pointer-events-none max-h-[300px] overflow-y-auto overflow-x-hidden"
				style="--cmdk-list-height: 196.0px;"
			>
				<div data-cmdk-list-sizer="">
					<div
						data-cmdk-group=""
						role="presentation"
						data-value="suggestions"
						class="[&amp;_[data-cmdk-group-heading]]:px-2 [&amp;_[data-cmdk-group-heading]]:py-1.5 [&amp;_[data-cmdk-group-heading]]:text-xs [&amp;_[data-cmdk-group-heading]]:font-medium [&amp;_[data-cmdk-group-heading]]:text-muted-foreground overflow-hidden p-1 text-foreground"
					>
						<div data-cmdk-group-heading="" aria-hidden="true" id="drEpVDOq0F">Suggestions</div>
						<div data-cmdk-group-items="" role="group" class="font-[Manjari]">
							<div
								data-cmdk-item=""
								data-value="മലയാളം"
								class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
								aria-selected="true"
								data-selected="true"
							>
								മലയാളം
							</div>
							<!--<Command-item>-->
							<div
								data-cmdk-item=""
								data-value="മലയലം"
								class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
							>
								മലയലം
							</div>
							<!--<Command-item>-->
							<div
								data-cmdk-item=""
								data-value="മലയാളം"
								class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
							>
								മലയളം
							</div>
							<!--<Command-item>-->
							<div
								data-cmdk-item=""
								data-value="മലയലം"
								class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
							>
								മലയലം
							</div>
							<!--<Command-item>-->
							<div
								data-cmdk-item=""
								data-value="മലയളം"
								class="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50"
							>
								മലയളം
							</div>
							<!--<Command-item>-->
						</div>
					</div>
					<!--<Command-group>-->
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	@font-face {
		font-family: Manjari;
		src: url('/fonts/Manjari-Regular.woff2');
		font-display: swap;
	}
</style>
