<div id="export" class="w-full scale-[.85] rounded-lg">
	<div
		class="pointer-events-none grid gap-4 rounded-lg border-l border-r border-t p-6 pb-16 dark:border-l-0 dark:border-r-0"
	>
		<div class="flex flex-col space-y-1.5 text-center sm:text-left">
			<h2
				id="8E_7iqdY1q"
				data-melt-dialog-title=""
				data-dialog-title=""
				class="text-lg font-semibold leading-none tracking-tight"
			>
				Export to PDF
			</h2>
			<!--<Dialog-title>-->
		</div>
		<!--<Dialog-header>-->
		<form>
			<h3 class="scroll-m-20 text-lg font-semibold tracking-tight">Layout</h3>
			<hr class="mt-2" />
			<div class="mt-6 space-y-4">
				<label for="twoColumn" class="mt-2 flex items-center space-x-2"
					><button
						data-state="unchecked"
						type="button"
						role="checkbox"
						aria-checked="false"
						aria-required="false"
						data-melt-checkbox=""
						data-checkbox-root=""
						class="checkbox peer box-content h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[disabled=true]:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground data-[disabled=true]:opacity-50"
						id="twoColumn"
						><div
							class="flex h-4 w-4 items-center justify-center text-current"
							data-checkbox-indicator=""
							data-state="unchecked"
						>
							<svg
								width="24"
								height="24"
								class="h-3.5 w-3.5 text-transparent"
								role="img"
								aria-label="check,"
								viewBox="0 0 15 15"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
								><path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
									fill="currentColor"
								></path></svg
							>
						</div></button
					><!--<Checkbox>-->
					<p>Two Column Layout</p></label
				>
				<label for="scenePageBreak" class="mt-2 flex items-center space-x-2"
					><button
						data-state="unchecked"
						type="button"
						role="checkbox"
						aria-checked="false"
						aria-required="false"
						data-melt-checkbox=""
						data-checkbox-root=""
						class="checkbox peer box-content h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[disabled=true]:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground data-[disabled=true]:opacity-50"
						id="scenePageBreak"
						><div
							class="flex h-4 w-4 items-center justify-center text-current"
							data-checkbox-indicator=""
							data-state="unchecked"
						>
							<svg
								width="24"
								height="24"
								class="h-3.5 w-3.5 text-transparent"
								role="img"
								aria-label="check,"
								viewBox="0 0 15 15"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
								><path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
									fill="currentColor"
								></path></svg
							>
						</div></button
					><!--<Checkbox>-->
					<p>Start scenes on new page</p></label
				>
			</div>
			<!-- <h3 class="mt-6 scroll-m-20 text-lg font-semibold tracking-tight">Password Protection</h3>
      <hr class="mt-2" />
      <div class="flex items-center gap-x-2">
        <button
          data-state="unchecked"
          type="button"
          role="checkbox"
          aria-checked="false"
          aria-required="false"
          data-melt-checkbox=""
          data-checkbox-root=""
          class="checkbox peer box-content h-4 w-4 shrink-0 rounded-sm border border-primary shadow focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 data-[disabled=true]:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground data-[disabled=true]:opacity-50"
          id="enableProtection"
          ><div
            class="flex h-4 w-4 items-center justify-center text-current"
            data-checkbox-indicator=""
            data-state="unchecked"
          >
            <svg
              width="24"
              height="24"
              class="h-3.5 w-3.5 text-transparent"
              role="img"
              aria-label="check,"
              viewBox="0 0 15 15"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              ><path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                fill="currentColor"
              ></path></svg
            >
          </div></button
        >
        <p>Enable Password Protection</p>
        <button
          aria-haspopup="dialog"
          aria-expanded="true"
          data-state="open"
          id="c5mCqA3RlP"
          data-melt-popover-trigger=""
          data-popover-trigger=""
          type="button"
          class="inline-flex h-9 w-9 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
        >
          <svg viewBox="0 0 24 24" width="1.2em" height="1.2em" class="text-[11px]"
            ><g
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              ><circle cx="12" cy="12" r="10"></circle><path d="M12 8v4m0 4h.01"></path></g
            ></svg
          >
        </button>
      </div> -->
		</form>
		<button
			type="button"
			data-melt-dialog-close=""
			data-dialog-close=""
			class="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
			><svg
				width="24"
				height="24"
				class="h-4 w-4"
				role="img"
				aria-label="cross 2,"
				viewBox="0 0 15 15"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg"
				><path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
					fill="currentColor"
				></path></svg
			> <span class="sr-only">Close</span></button
		>
	</div>
</div>

<style>
	#export {
		background-image: linear-gradient(
			to bottom,
			hsl(var(--background)) 50%,
			hsl(var(--muted) / 0.4)
		);
	}
</style>
