<script lang="ts">
	import Button from '$ui/button/button.svelte';
	import Copy from '~icons/lucide/copy';
</script>

<div id="publish" class="mt-4 rounded-md">
	<div
		class="pointer-events-none rounded-md border-l border-r border-t p-4 pb-16 text-foreground dark:border-l-0 dark:border-r-0"
	>
		<div class="mb-4 w-full shadow-lg">Publish Screenplay</div>
		<p class="mb-4 text-sm text-muted-foreground">
			Click the link below to copy and share with the world.
		</p>
		<Button
			variant="outline"
			class="pointer-events-none relative mb-0 grid w-full grid-cols-[auto_auto] items-center justify-between px-2 py-0"
		>
			<span class="truncate text-sm font-medium">
				https://yavanika.pictures/read/films/2985024c-7f1d-456d-87f4-123sadf1
			</span>
			<span class="flex items-center gap-x-2 rounded bg-accent p-1 text-sm">
				<Copy />
			</span>
		</Button>
	</div>
</div>

<style>
	#publish {
		background-image: linear-gradient(
			to bottom,
			hsl(var(--background)) 40%,
			hsl(var(--muted) / 0.4)
		);
	}
</style>
