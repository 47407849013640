<div
	role="menu"
	class="z-50 mx-auto w-[13.5rem] scale-90 rounded-md border bg-popover p-1 text-popover-foreground shadow-md focus:outline-none"
>
	<div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="sceneHead"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 640 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M560 160a80 80 0 1 0 0-160a80 80 0 1 0 0 160M55.9 512h523c33.8 0 61.1-27.4 61.1-61.1c0-11.2-3.1-22.2-8.9-31.8l-132-216.3C495 196.1 487.8 192 480 192s-15 4.1-19.1 10.7l-48.2 79L286.8 81c-6.6-10.6-18.3-17-30.8-17s-24.1 6.4-30.8 17L8.6 426.4C3 435.3 0 445.6 0 456.1C0 487 25 512 55.9 512"
					></path></svg
				> Scene Heading</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="subSceneHead"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 512 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M256 32c12.5 0 24.1 6.4 30.8 17l216.6 345.4c5.6 8.9 8.6 19.2 8.6 29.7c0 30.9-25 55.9-55.9 55.9H55.9C25 480 0 455 0 424.1c0-10.5 3-20.8 8.6-29.7L225.2 49c6.6-10.6 18.3-17 30.8-17m65 192l-65-103.6l-79.1 126.1l18.3 24.4c6.4 8.5 19.2 8.5 25.6 0l25.6-34.1c6-8.1 15.5-12.8 25.6-12.8z"
					></path></svg
				>Sub-Scene</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="action"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 448 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M320 48a48 48 0 1 0-96 0a48 48 0 1 0 96 0M125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8.1 5.6.3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9l-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4l5.1 12.3c15 35.8 49.9 59.1 88.7 59.1H384c17.7 0 32-14.3 32-32s-14.3-32-32-32h-21.3c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15C186.6 97.8 175 96 163.3 96c-31 0-60.8 12.3-82.7 34.3l-23.2 23.1c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5l11.5-25.9l-9.5-6a95.394 95.394 0 0 1-37.9-44.9z"
					></path></svg
				> Action</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="character"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 640 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M74.6 373.2c41.7 36.1 108 82.5 166.1 73.7c6.1-.9 12.1-2.5 18-4.5c-9.2-12.3-17.3-24.4-24.2-35.4c-21.9-35-28.8-75.2-25.9-113.6c-20.6 4.1-39.2 13-54.7 25.4c-6.5 5.2-16.3 1.3-14.8-7c6.4-33.5 33-60.9 68.2-66.3c2.6-.4 5.3-.7 7.9-.8l19.4-131.3c2-13.8 8-32.7 25-45.9c18.6-14.3 50.9-30.5 103.6-35.3l-2.4-2.1c-20.2-15.6-72.4-41.6-185.1-24.5S20.5 63 5.7 83.9C0 91.9-.8 102 .6 111.8l24.2 164.3c5.5 37.3 21.5 72.6 49.8 97.2zm87.7-219.6c4.4-3.1 10.8-2 11.8 3.3c.1.5.2 1.1.3 1.6c3.2 21.8-11.6 42-33.1 45.3S99.8 192 96.6 170.3c-.1-.5-.1-1.1-.2-1.6c-.6-5.4 5.2-8.4 10.3-6.7c9 3 18.8 3.9 28.7 2.4s19.1-5.3 26.8-10.8zM261.6 390c29.4 46.9 79.5 110.9 137.6 119.7s124.5-37.5 166.1-73.7c28.3-24.5 44.3-59.8 49.8-97.2l24.2-164.3c1.4-9.8.6-19.9-5.1-27.9c-14.8-20.9-57.3-61.2-170-78.3s-164.8 8.9-185 24.5c-7.8 6-11.5 15.4-12.9 25.2l-24.2 164.3c-5.5 37.3-.4 75.8 19.6 107.7zm142.9-154.7c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5.1-1.1.2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1.5-.2 1.1-.3 1.6c-1 5.3-7.4 6.4-11.8 3.3zm136.2 15.5c-1 5.3-7.4 6.4-11.8 3.3c-7.7-5.5-16.8-9.3-26.8-10.8s-19.8-.6-28.7 2.4c-5.1 1.7-10.9-1.3-10.3-6.7c.1-.5.1-1.1.2-1.6c3.2-21.8 23.2-36.8 44.7-33.5s36.3 23.5 33.1 45.3c-.1.5-.2 1.1-.3 1.6zM530 350.2c-19.6 44.7-66.8 72.5-116.8 64.9s-87.1-48.2-93-96.7c-1-8.3 8.9-12.1 15.2-6.7c23.9 20.8 53.6 35.3 87 40.3s66.1.1 94.9-12.8c7.6-3.4 16 3.2 12.6 10.9z"
					></path></svg
				> Character</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="parenthetical"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"><span>()</span> Parenthetical</span>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="dialogue"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 512 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6.6-1 1.1-1.3 1.4l-.3.3c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64a32 32 0 1 1 0-64m128 0a32 32 0 1 1 0 64a32 32 0 1 1 0-64m96 32a32 32 0 1 1 64 0a32 32 0 1 1-64 0"
					></path></svg
				> Dialogue</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="unchecked"
			aria-checked="false"
			data-value="intercut"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"><div></div></span>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 512 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="m256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0zm22.6 150.6l118.2 118.2c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6zM64 112a48 48 0 1 1 96 0a48 48 0 1 1-96 0m48 240a48 48 0 1 1 0 96a48 48 0 1 1 0-96"
					></path></svg
				> Intercut</span
			>
		</div>
		<div
			role="menuitemradio"
			data-state="checked"
			aria-checked="true"
			data-value="transition"
			data-orientation="vertical"
			tabindex="-1"
			data-melt-menubar-menu-radio-item=""
			data-menu-radio-item=""
			class="relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none data-[disabled]:pointer-events-none data-[highlighted]:bg-accent data-[highlighted]:text-accent-foreground data-[disabled]:opacity-50"
		>
			<span class="absolute left-2 flex h-3.5 w-3.5 items-center justify-center"
				><div>
					<svg
						width="24"
						height="24"
						class="h-4 w-4 fill-current"
						role="img"
						aria-label="dot filled,"
						viewBox="0 0 15 15"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
						><path
							d="M9.875 7.5C9.875 8.81168 8.81168 9.875 7.5 9.875C6.18832 9.875 5.125 8.81168 5.125 7.5C5.125 6.18832 6.18832 5.125 7.5 5.125C8.81168 5.125 9.875 6.18832 9.875 7.5Z"
							fill="currentColor"
						></path></svg
					>
				</div></span
			>
			<span class="mr-8 grid grid-cols-[35px_1fr]"
				><svg viewBox="0 0 512 512" width="1.2em" height="1.2em" class="mr-2"
					><path
						fill="currentColor"
						d="M32 96h320V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9S352 236.8 352 223.8V160H32c-17.7 0-32-14.3-32-32s14.3-32 32-32m448 256c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6v64h320z"
					></path></svg
				> Transition</span
			>
		</div>
	</div>
</div>
