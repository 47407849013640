<div class="mt-2 hidden h-full translate-x-1/3 items-center sm:flex">
	<a
		class="effect1 relative font-serif text-foreground no-underline transition-all duration-300 hover:text-background"
		href="/signup"
	>
		Get Started
		<span
			class="bg bg-foreground transition-all duration-300 before:bg-foreground after:bg-foreground"
		></span>
	</a>
</div>

<style>
	.effect1 {
		padding: 10px 30px 10px 20px;
		-webkit-transform: scale(2);
		-ms-transform: scale(2);
		transform: scale(2); /*change scale(3) to scale(1)*/
	}

	.effect1 .bg {
		width: 20px;
		height: 2px;
		position: absolute;
		right: 0;
		top: 50%;
		z-index: -1;
	}

	.effect1:hover {
		padding-right: 20px;
	}

	.effect1:hover .bg {
		height: 100%;
		width: 100%;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}

	.effect1 .bg:before,
	.effect1 .bg:after {
		content: '';
		height: 2px;
		width: 10px;
		position: absolute;
		right: -2px;
		-webkit-transition: all 0.3s;
		-o-transition: all 0.3s;
		transition: all 0.3s;
	}

	.effect1 .bg:before {
		bottom: 3px;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.effect1 .bg:after {
		top: 3px;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.effect1:hover .bg:before,
	.effect1:hover .bg:after {
		right: 0;
	}

	.effect1:hover .bg:before {
		bottom: 6px;
	}

	.effect1:hover .bg:after {
		top: 6px;
	}
</style>
