<script lang="ts">
	import { onMount } from 'svelte';

	const films = ['Manjumel Boys', 'Aavesham', 'Premalu', 'Aadujeevitham'];

	let time = 300;
	let waitTime = 500;

	let text = '';
	let filmIndex = 0;

	let intervalTimer: NodeJS.Timeout;
	let timeoutTimer: number;

	onMount(() => {
		typewriteFilm(films[filmIndex]);

		return () => {
			clearInterval(intervalTimer);
			clearTimeout(timeoutTimer);
		};
	});

	function typewriteFilm(film: string) {
		let i = 0;
		intervalTimer = setInterval(() => {
			const letter = film[i];
			text = text + letter;
			i = i + 1;
			if (i === film.length) {
				clearInterval(intervalTimer);
				wait(() => deleteFilm(film));
			}
		}, time);
	}

	function wait(callback: Function) {
		timeoutTimer = setTimeout(callback, waitTime);
	}

	function deleteFilm(film: string) {
		let i = film.length - 1;
		intervalTimer = setInterval(() => {
			text = film.slice(0, i);
			i = i - 1;
			if (i < 0) {
				text = '';
				clearInterval(intervalTimer);
				if (filmIndex !== films.length - 1) {
					filmIndex++;
				} else {
					filmIndex = 0;
				}
				typewriteFilm(films[filmIndex]);
			}
		}, time);
	}
</script>

<div
	id="title"
	class="relative grid place-content-center space-y-4 border-t bg-background py-12 text-center font-mono"
>
	<p>
		<span class="typewrite uppercase">
			{text}
		</span>
	</p>

	<p>Written By</p>

	<p>You</p>
</div>

<style>
	.typewrite {
		border-right: 0.08em solid #fff;
	}

	#title {
		background-image: linear-gradient(to top, transparent 80%, hsl(var(--muted) / 0.4)),
			linear-gradient(to bottom, hsl(var(--background)) 80%, hsl(var(--muted) / 0.4));
	}
</style>
